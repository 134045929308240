<script>
import options from './SendCalculation.options'
export default options
</script>

<template>
    <article class="nyk-contact-card" :class="sendCalculationClassNames">
        <component
            v-if="showSendCalculation"
            :is="currentStepName"
            :nextStepCallback="validateInputAndProceed"
            :previousStepCallback="returnToPreviousStep"
            :displayInputError="displayInputError"
            :currentStepTexts="currentStepTexts"
            :hasPreviousStep="hasPreviousStep"
            :resetSignupForm="onClose"
            :getNamedFieldInputData="getNamedFieldInputData"
            :showFieldErrorMessage="showFieldErrorMessage"
            :stepCounterText="stepCounterText"
            :redirectLink="redirectLink"
            :onCloseCallback="onClose"
            :labels="labels" />
    </article>
</template>
