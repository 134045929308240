import { computed, onBeforeMount, ref } from "vue"
import MultiSelectDropdown from "../../../Frontend/src/components/MultiSelectDropdown/MultiSelectDropdown.vue"

export default {
    name: "ArticlesInsight",
    components: {
        MultiSelectDropdown,
    },
    props: {
        apiUrl: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        getMoreButtonText: {
            type: String,
            required: true,
        },
        takeCount: {
            type: Number,
            required: true,
        },
        subjects: {
            type: Array,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        excludePages: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const typeOptions = ref([])
        const subjectOptions = ref([])
        const results = ref([])
        const currentPage = ref(1)
        const take = ref(props.takeCount)
        const totalArticleCount = ref(0)
        const selectedTypes = ref([])
        const selectedSubjects = ref([])
        const fetching = ref(false)

        onBeforeMount(() => {
            if (props.types) {
                formatTypes()
            }
            if (props.subjects) {
                formatSubjects()
            }
            if (props.apiUrl) {
                fetchArticles()
            }
        })

        const loadMore = computed(() => {
            return totalArticleCount.value > results.value.length
        })

        const formatTypes = () => {
            props.types.forEach((type) => {
                typeOptions.value.push({
                    name: type.name,
                    value: type.code,
                    selected: false,
                })
            })
            selectedTypes.value = typeOptions.value
        }

        const formatSubjects = () => {
            props.subjects.forEach((subject) => {
                subjectOptions.value.push({
                    name: subject.name,
                    value: subject.code,
                    selected: false,
                })
            })
        }

        const formatResults = (data) => {
            totalArticleCount.value = data.totalArticleCount

            if (currentPage.value === 1) {
                results.value = []
            }

            data.selectedArticles.forEach((article) => {
                results.value.push({
                    title: article.title,
                    tag: article.format,
                    category: article.newsSubjectTaxonomy,
                    datePublished: article.datePublished,
                    url: article.url,
                    thumbnailUrl: article.thumbnailUrl,
                    thumbnailUrlMobile: article.thumbnailUrlMobile,
                    thumbnailUrlTablet: article.thumbnailUrlTablet,
                })
            })
        }

        const handleSubjectsCallback = (res) => {
            const selected = res.filter((subject) => subject.selected === true)
            selectedSubjects.value = selected
            currentPage.value = 1
            fetchArticles()
        }

        const handleTypesCallback = (res) => {
            let selected = res.filter((type) => type.selected === true)
            if (selected.length === 0) {
                selected = typeOptions.value
            }
            selectedTypes.value = selected
            currentPage.value = 1
            fetchArticles()
        }

        const getMoreResults = () => {
            currentPage.value += 1
            fetchArticles()
        }

        const fetchArticles = () => {
            let url = props.apiUrl
            url += `?skip=${take.value * currentPage.value - take.value}&take=${take.value}`

            let typeCodes = "&anyTypes="
            selectedTypes.value.forEach((type, index) => (typeCodes += `${index > 0 ? "," : ""}${type.value}`))
            url += typeCodes

            let subjectCodes = "&anyCategory="
            selectedSubjects.value.forEach((subject, index) => (subjectCodes += `${index > 0 ? "," : ""}${subject.value}`))
            url += subjectCodes

            if (props.excludePages?.length > 0) {
                let exceludePages = "&excludedArticles=" + props.excludePages.join()
                url += exceludePages
            }

            fetching.value = true

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    formatResults(data)
                    fetching.value = false
                })
                .catch((error) => {
                    console.error("Error:", error)
                })
        }

        return {
            typeOptions,
            subjectOptions,
            results,
            loadMore,
            fetching,
            getMoreResults,
            handleSubjectsCallback,
            handleTypesCallback,
        }
    },
}