export default function (inputData, userInput, stepsConfigAndOrder, currentStepName, callback, skipValidation = false) {
  let displayInputError = false

    const advanceToNextStep = function () {
        stepsConfigAndOrder.find((stepData) => stepData.name === currentStepName).completed = true
        displayInputError = false
        const allStepInputComplete = userInput.every((stepField) => stepField.isValid)

        if (allStepInputComplete) {
            const lastStep = stepsConfigAndOrder[stepsConfigAndOrder.length - 2].name
            if (lastStep === currentStepName) {
                callback(userInput)
            }
        }
    }

    if (skipValidation) {
        advanceToNextStep()
        return false
    }

  inputData.forEach((input) => {
    const inputDatafield = userInput.find((field) => field.field === input.field)
    inputDatafield.isValid = inputDatafield.validator(input.value)

    // we are validating fields one by one
    if (inputDatafield.isValid) {
      inputDatafield.value = input.value
    } else {
      displayInputError = true
    }
  })

  const stepInputComplete = userInput
    .filter((dataField) => {
      return inputData.some((inputfield) => inputfield.field === dataField.field)
    })
    .every((stepField) => stepField.isValid)

  if (stepInputComplete) {
    advanceToNextStep()
  }
  return displayInputError
}
