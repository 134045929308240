    <script>
import options from "./ChatFeedbackPhoneNumber.options"
export default options
</script>

<template>
    <div class="nyk-chat-feedback-phone-number">
        <span class="nyk-chat-feedback-phone-number__heading">{{ getLabel("reviewNegativeHeading") }}</span>
        <div class="nyk-chat-feedback-phone-number__description" v-html="feedbackApiMessage"></div>
        <div class="nyk-chat-feedback-phone-number__form">
            <input
                ref="inputRef"
                class="nyk-chat-feedback-phone-number__input"
                v-model="phoneNumber"
                type="tel" 
                maxlength="8"
                pattern="[0-9]+"
                :placeholder="getLabel('reviewAddCommentPlaceholder')"
                @keydown.enter="handleEnterKeydown" />
                <span v-if="showPhoneNumberHelpMessage && !phoneNumberValid" class="nyk-chat-feedback-phone-number__form-error-text">{{getLabel("reviewPhoneNumberHelpMessage")}}</span>
            <div class="nyk-chat-feedback-phone-number__disclaimer" v-html="getLabel('reviewInputPhoneNumberDisclaimerHTML')"></div>
            <button class="nyk-chat-feedback-phone-number__send-button" @click="sendConversationFeedback({phoneNumber})" @keydown.tab="handleKeydown" data-order="last">
                <span class="nyk-chat-feedback-phone-number__send-button-text" :class="{'nyk-visibility-hidden': isLoading}">{{ getLabel("reviewSendButton") }}
                    <div class="nyk-button nyk-button--link-circle-arrow right">
                        <div class="nyk-button__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12" role="presentation" aria-hidden="true">
                                <path fill="#07094A"
                                    d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                            </svg>
                        </div>
                    </div>
                </span>
                <Loader v-if="isLoading" classNames="nyk-loader--inline nyk-loader--size-small nyk-loader--color-primary-dark" />
            </button>
        </div>
    </div>
</template>