import { registerGtmDataLayer } from '../../../../Features/Blocks/Video/gtmDataLayerService';

export default {
    namespaced: true,
    state: {
        suggestions: [],
        results: [],
        facts: [],
        totalNumberOfResults: 0,
        unfilteredNumberOfResults: 0,
        pageNumber: 0,
        pageTotalSize: 0,
        activeFilter: ""
    },
    mutations: {
        set_search_suggestions(state, suggestions) {
            state.suggestions = suggestions
        },
        set_clear_search_suggestions(state) {
            state.suggestions = []
        },
        set_search_results(state, results) {
            state.results = results
        },
        set_clear_search_results(state) {
            state.results = []
        },
        set_update_search_results(state, results) {
            let array = []
            Array.from(state.results).forEach((result) => array.push({...result}))
            results.forEach((result) => array.push(result))
            state.results = array
        },
        set_facts(state, facts) {
            state.facts = facts
        },
        set_clear_facts(state) {
            state.facts = []
        },
        set_total_number_of_results(state, totalNumberOfResults) {
            state.totalNumberOfResults = totalNumberOfResults
        },
        set_unfiltered_number_of_results(state, unfilteredNumberOfResults) {
            state.unfilteredNumberOfResults = unfilteredNumberOfResults
        },
        set_active_filter(state, filter) {
            state.activeFilter = filter
        },
        set_page_number(state, page) {
            state.pageNumber = page
        },
        set_page_total_size(state, page) {
            state.pageTotalSize = page
        },
    },
    actions: {
        performSearchSuggestions(context, config) {
            const request = new Request(config.apiUrl + "?q=" + config.query)

            fetch(request)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw new Error("Didn't work")
                    }
                })
                .then(json => {
                    context.commit('set_search_suggestions', json.results)
                })
                .catch(err => {
                    console.error(err)
                })
        },
        clearSuggestions(context) {
            context.commit('set_clear_search_suggestions')
        },
        performSearch(context, config) {
            let requestUrl = config.apiUrl + `?searchTerm=${config.query}&language=${config.lang}&pageNumber=${config.page}`
            if (context.state.activeFilter) {
                requestUrl += `&searchSection=${encodeURIComponent(context.state.activeFilter)}`
            }
            const request = new Request(requestUrl)

            fetch(request)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw new Error("Didn't work")
                    }
                })
                .then(json => {
                    let dataLayer = {
                        'event': 'track-event',
                        'eventCategory': "intern søgning",
                        'eventAction': "submit",
                        'eventLabel': config.query,
                        'eventValue': json.unfilteredNumberOfHits,
                        'eventNoninteraction': true,
                        'searchNumresult': json.unfilteredNumberOfHits
                    };

                    registerGtmDataLayer(dataLayer);


                    if (config.page === 1) {
                        context.commit('set_search_results', json.hits)   
                    } else {
                        context.commit('set_update_search_results', json.hits)  
                    }
                    context.commit('set_page_number', config.page)
                    context.commit('set_page_total_size', json.pagingPagesTotal)
                    context.commit('set_total_number_of_results', json.numberOfHits)
                    context.commit('set_unfiltered_number_of_results', json.unfilteredNumberOfHits)
                    context.commit('set_facts', json.sectionFacets)
                })
                .catch(err => {
                    console.error(err)
                })
        },
        clearResults(context) {
            context.commit('set_clear_search_results')
            context.commit('set_clear_facts')
            context.commit('set_total_number_of_results', 0)
            context.commit('set_active_filter', "")
            context.commit('set_page_number', 0)
            context.commit('set_page_total_size', 0)
            context.commit('set_unfiltered_number_of_results', 0)
        },
        facts(context, config) {
            context.commit('set_facts', config.facts)
        },
        totalNumberOfResults(context, config) {
            context.commit('set_total_number_of_results', config.number)
        },
        activeFilter(context, config) {
            context.commit('set_active_filter', config.filter)
        },
        pageNumber(context, config) {
            context.commit('set_page_number', config.page)
        },
        pageTotalSize(context, config) {
            context.commit('set_page_total_size', config.page)
        }
    }
}