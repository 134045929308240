import { useStore } from "vuex"
import {computed, inject} from "vue"
export default {
    name: "ChatMenu",
    components: {
    },
    props: {
    },
    setup() {
        const store = useStore()
        const handleKeydown = inject('handleKeydown');
        function deleteMessages() {
            store.dispatch("chatPanel/deleteMessageHistory");
            // Message other tabs to delete the session
            const bc = new BroadcastChannel("nyk-chat-conversation-id");
            bc.postMessage("deleteChatConversation");
            bc.close();
        }
        function downloadConversation() {
            store.dispatch("chatPanel/downloadConversation");
        }
        const conversationStarted = computed(() => {
            return store.state.chatPanel.conversation?.id;
        })
        function getLabel(label) {
            const returnLabel = store.state.chatPanel.labels[label];
            if (!returnLabel) console.error("Label does not exist", label);
            return returnLabel ? returnLabel : `[${label}]`;
        }
        const privacyPolicyUrl = computed(() => {
            return store.state.chatPanel.conversation?.state?.privacyPolicyUrl
        })
        return {
            handleKeydown,
            downloadConversation,
            conversationStarted,
            privacyPolicyUrl,
            getLabel,
            deleteMessages
        }
    }
}