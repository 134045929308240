import { ref, watch, onMounted, computed, onUnmounted, nextTick  } from "vue";
import GtmVideoService from "./GtmVideoService";

export default {
  name: "VideoBlock",
  setup() {
    const cookieAccepted = ref(false);
    const lazyLoad = ref(false);
    const timeout = ref(null);
    const videoPlaying = ref(false);
    const youtubePlayer = ref(null)
    const youtubeIframeAPIReady = ref(false)
    const videoWrapperElementRef = ref(null);
    const youtubeVideoElementRef = ref(null);
    const playButtonElementRef = ref(null)

    const initCookieAccepted = () => {
      if (!youtubeIframeAPIReady.value) {
        insertYoutubeApi();
      }
      playOrQueue();
    };

    onMounted(() => {
      cookieAccepted.value = process.env.NODE_ENV === "development" ? true : CookieInformation.getConsentGivenFor(
          "cookie_cat_functional"
        );
    });

    onUnmounted(() => {
      window.removeEventListener("CookieInformationConsentGiven", handleCookieConsent);
    });

    
    watch(cookieAccepted, (newCookieAccepted, oldCookieAccepted) => {
      if (newCookieAccepted && newCookieAccepted !== oldCookieAccepted) {
        initCookieAccepted();
      }
    });

    window.youTubeCallbackQueue = window.youTubeCallbackQueue || [];

    const acceptCookieConsent = () => {
      CookieConsent.renew();
      window.addEventListener(
        "CookieInformationConsentGiven",
        function (event) {
          cookieAccepted.value = CookieInformation.getConsentGivenFor(
            "cookie_cat_functional"
          );
        },
        false
      );
    };

    const showPlaceholder = computed(() => {
      return !videoPlaying.value
    })

    const playOrQueue = () => {
        if (window.youTubeIframeAPIReady) {
          initVideo();
        }
        else {
          window.youTubeCallbackQueue.push(initVideo);
          // triggered when YouTube iFrame API is ready (only listens to global var)
          window.onYouTubeIframeAPIReady = () => onYouTubeIframeAPIReady();
        }
      }

    const insertYoutubeApi = () => {
      const newScriptEl = document.createElement("script");
      newScriptEl.src = "https://www.youtube.com/iframe_api";

      const firstScriptEl = document.getElementsByTagName("script")[0];
      firstScriptEl.parentNode.insertBefore(newScriptEl, firstScriptEl);
      youtubeIframeAPIReady.value = true;
    };

    const onYouTubeIframeAPIReady = () => {
      youtubeIframeAPIReady.value = true;
      window.youTubeCallbackQueue.forEach((func) => func());
    };

    const onPlayerReady = () => {
      if (lazyLoad.value) {
        playYoutubeVideo();
      }
    };

    function playYoutubeVideo() {
      if (youtubePlayer.value?.playVideo) {
        const iFrame = youtubePlayer.value.getIframe();
        iFrame.contentWindow.focus();
        youtubePlayer.value.playVideo();
      }
    };

    const onPlayerStateChange = (state) => {

      if (state.data == window.YT.PlayerState.PLAYING) {
        videoPlaying.value = true;
      }

      if (state.data == window.YT.PlayerState.BUFFERING) {
        clearTimeout(timeout.value);
      }

      // when video has ended show placeholder and rewind
      // this make it act like before youtube changes of sep. 2018
      if (state.data == window.YT.PlayerState.ENDED) {
        videoPlaying.value = false;
      }
    };

    const initVideo = () => {
      const youtubeVideoElementRefID = youtubeVideoElementRef.value.dataset.ytPlayerId;
      const youtubeId = youtubeVideoElementRef.value.dataset.youtubeId;

      youtubePlayer.value = new YT.Player(youtubeVideoElementRefID, {
        height: "390",
        width: "640",
        host: "https://www.youtube-nocookie.com",
        videoId: youtubeId,
        playerVars: {
          showinfo: 0,
          rel: 0,
          hl: "da-dk",
        },
        events: {
          onReady: () => onPlayerReady(),
          onStateChange: (state) => onPlayerStateChange(state),
        },
      });
      // tracking
      let gtmVideoService = new GtmVideoService(youtubePlayer.value, youtubeVideoElementRefID);
    };

    const localVideoElementRef = ref(null)

    const playLocalVideo = async() => {
        videoPlaying.value = true
        await nextTick();
        localVideoElementRef.value.focus()
        localVideoElementRef.value.play()
    }
 
    return {
      playYoutubeVideo,
      videoPlaying,
      showPlaceholder,
      playButtonElementRef,
      localVideoElementRef,
      cookieAccepted,
      acceptCookieConsent,
      playLocalVideo,
      videoWrapperElementRef,
      youtubeVideoElementRef
    };
  },
};
