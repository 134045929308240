<script>
import options from "./ContactCard.options"
export default options
</script>

<template>
  <article class="nyk-contact-card" :id="anchorId" :class="contactCardClassNames">
    <component
      :is="currentStepName"
      :nextStepCallback="validateInputAndProceed"
      :previousStepCallback="returnToPreviousStep"
      :displayInputError="displayInputError"
      :currentStepTexts="currentStepTexts"
      :hasPreviousStep="hasPreviousStep"
      :resetSignupForm="resetSignupForm"
      :getNamedFieldInputData="getNamedFieldInputData"
      :showFieldErrorMessage="showFieldErrorMessage"
      :stepCounterText="stepCounterText"
      :imageSource="imageSource"
      :imageAltText="imageAltText"
      :labels="labels" 
      :currentStep="currentStep"/>
  </article>
</template>
