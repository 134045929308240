import { createApp } from "vue";
import options from "./ChatPanelFloatingTrigger.options";

const el = ".nyk-component--chat-panel-floating-trigger";

export default function (Store) {
  const DOM = document.querySelectorAll(el);
  DOM.forEach((ChatPanelFloatingTrigger) => {
    const template = ChatPanelFloatingTrigger.outerHTML;
    const chatPanelActionId = ChatPanelFloatingTrigger.dataset.chatPanelActionId;
    const floatingTriggerText = ChatPanelFloatingTrigger.dataset.floatingTriggerText ? JSON.parse(ChatPanelFloatingTrigger.dataset.floatingTriggerText) : undefined;

    options.template = template;
    const component = createApp(options, { chatPanelActionId, floatingTriggerText }).use(Store);
    component.mount(ChatPanelFloatingTrigger);
  });
}
