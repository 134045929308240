import { onBeforeUnmount, onMounted } from "vue";
import _signupStepsCommonProps from "../../foundation/js/configurations/_signupStepsCommonProps"

export default {
  name: "ContactStepIntro",
  props: _signupStepsCommonProps,
  setup(props) {
    
    const handleAnchorNavigationSkip = (() => {
      if(props && props.currentStep === 0) {
        props.nextStepCallback([])
      }
    })

    onMounted (() => {
      document.addEventListener("triggerAnchorSkip", handleAnchorNavigationSkip, { once: true });
    })

    onBeforeUnmount (() => {
      document.removeEventListener("triggerAnchorSkip", handleAnchorNavigationSkip);
    })
  },
}
