import { saveAs } from "foundation/js/file-download";

function findLastBotResponseId(messages) {
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].source === "bot") {
      return messages[i].id;
    }
  }
  return null;
}

function createMessageObject(textContent, id) {
  return {
    id: id,
    dateCreated: null,
    language: null,
    source: "client",
    elements: [
      {
        type: "text",
        payload: {
          html: null,
          text: textContent,
          links: null,
        },
      },
    ],
  };
}

function createStatusMessageObject(statusText) {
  return {
    ...createMessageObject("", "status_message"),
    source: "system",
    elements: [
      {
        type: "statusMessage",
        payload: {
          statusMessage: statusText,
        },
      },
    ],
  };
}

function createClientMessageId(state) {
  return state.latestResponseId
    ? (parseInt(state.latestResponseId) + 1).toString()
    : undefined;
}

export default {
  namespaced: true,
  state: {
    conversation: undefined,
    messages: [],
    chatWindowOpen: false,
    showFeedback: false,
    connectionError: false,
    labels: undefined,
    feedbackActionId: undefined,
    messageFeedback: [],
    latestResponseId: undefined,
    maxCharacters: undefined,
    isAwaitingResponse: false,
    feedbackStep: 'ChatFeedbackInput',
    feedbackPayload: undefined,
    expiredMessageAdded: false,
    previousWasHumanAgent: false,
    triggerActionId: undefined,
  },
  mutations: {
    set_is_awaiting_response(state, value) {
      state.isAwaitingResponse = value;
    },
    set_conversation(state, value) {
      state.conversation = value;
    },
    set_feedback_step(state,value) {
      state.feedbackStep = value
    },
    set_feedback_payload(state,value) {
      state.feedbackPayload = value
    },
    append_messages(state, messages) {
      if (!messages || !messages.length) {
        return true
      }
      // Filter system messages since these are not supplied by Boost api and should not be filteren on latestResponseId
      const newMessagesList = messages.filter(message => message.source === "system" || (state.latestResponseId ? parseInt(message.id) > parseInt(state.latestResponseId) : true));
      let index = 0;
      while (index < newMessagesList.length) {
        const message = newMessagesList[index];
        if (message.source === "client") {
          break
        }
        if (message.isHumanAgent && !state.previousWasHumanAgent) {
          const newMessage = createStatusMessageObject(state.labels["customerServiceJoined"]);
          newMessagesList.splice(index, 0, newMessage);
          state.previousWasHumanAgent = true;
          index++;
        } if (!message.isHumanAgent && state.previousWasHumanAgent) {
          const newMessage = createStatusMessageObject(state.labels["virtualAgentJoined"]);
          newMessagesList.splice(index, 0, newMessage);
          state.previousWasHumanAgent = false;
          index++;
        }
        index++;
      }
    
      if (state.messages && state.messages.length) {
        if (newMessagesList.length) {
          state.messages = [...state.messages, ...newMessagesList];
        }
      } else {
        state.messages = [...newMessagesList];
      }
    },
    set_chat_window_open(state, value) {
      const sessionStorageString = sessionStorage.getItem("chatPanelConversation");
      if (sessionStorageString) {
          const sessionStorageObject = JSON.parse(sessionStorageString);
          sessionStorageObject.windowOpen = value;
          sessionStorage.setItem("chatPanelConversation", JSON.stringify(sessionStorageObject));
      }
      state.chatWindowOpen = value;
    },
    set_labels(state, payload) {
      state.labels = payload;
    },
    set_feedback_action_id(state, value) {
      state.feedbackActionId = value;
    },
    set_show_feedback(state, value) {
      state.showFeedback = value;
    },
    set_message_feedback(state, payload) {
      const { id, value } = payload;
      const index = state.messageFeedback.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.messageFeedback[index].value = value;
      } else {
        state.messageFeedback.push({ id, value });
      }
    },
    set_connection_error(state, value) {
      state.connectionError = value;
    },
    set_latest_response_id(state, value) {
      state.latestResponseId = value;
    },
    setPollingInterval(state, intervalId) {
      state.pollingInterval = intervalId;
    },
    clearPollingInterval(state) {
      if (state.pollingInterval) {
        clearInterval(state.pollingInterval);
        state.pollingInterval = null;
      }
    },
    expired_message_added(state, value) {
      state.expiredMessageAdded = value;
    },
    set_feedback_api_message(state, value) {
      state.feedbackApiMessage = value
    },
    set_floating_trigger_action_id(state, triggerActionId) {
      state.triggerActionId = triggerActionId;
    },
    reset_chat_client_state(state) {
      state.conversation = undefined;
      state.messages = [];
      state.showFeedback = false;
      state.connectionError = false;
      state.chatWindowOpen = false;
      state.messageFeedback = [];
      state.latestResponseId = undefined;
      state.maxCharacters = undefined;
      state.isAwaitingResponse = false;
      state.feedbackApiMessage = undefined;
      state.feedbackStep = 'ChatFeedbackInput';
      state.triggerActionId = undefined;
      sessionStorage.removeItem("chatPanelConversation");
    }
  },
  actions: {
    openChatAndHandleActionId({ state, commit, getters, dispatch }, actionId) {
      commit("set_floating_trigger_action_id", actionId);
      if (!state.chatWindowOpen) {
        commit("set_chat_window_open", true);
      }
      if (getters["isConversationOngoing"]) {

        dispatch("sendActionId", {actionId, isFloatingTrigger: true});
      }
    },
    async startConversation({ state, commit, dispatch }) {
      const request = new Request(process.env.CHATPANEL_START_CONVERSATION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( state.triggerActionId ? { actionId: state.triggerActionId } : {})
      });

      try {
        commit("set_is_awaiting_response", true);

        const response = await fetch(request);

        if (!response.ok) {
          commit("set_connection_error", true);
          throw new Error("Failed to start conversation");
        }
        else {
          commit("set_connection_error", false);
        }

        const json = await response.json();
        dispatch("handleConversationData", json);
        return true;
      } catch (err) {
        console.error("Error starting conversation:", err);
        throw new Error("Unable to start conversation: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    async resumeConversation({ commit, dispatch, state}) {
      const sessionStorageChatPanelConversation = sessionStorage.getItem('chatPanelConversation');
      if (!sessionStorageChatPanelConversation) {
        console.error("No conversation to resume");
        return false
      }
      const parsedSessionStorageConversation = JSON.parse(sessionStorageChatPanelConversation);
      const id = parsedSessionStorageConversation?.conversation?.id;
      const url = `${process.env.CHATPANEL_RESUME_CONVERSATION}?conversation_id=${id}`;

      const request = new Request(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        commit("set_is_awaiting_response", true);

        const response = await fetch(request);

        if (!response.ok) {
          commit("set_connection_error", true);
          throw new Error(
            "Failed to resume conversation: " + response.statusText
          );
        }
        else {
          commit("set_connection_error", false);
        }
        const json = await response.json();
        dispatch("handleConversationData", json);
        return true;
      } catch (err) {
        console.error("Error starting conversation:", err);
        throw new Error("Unable to start conversation: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    handleConversationData({ commit, dispatch, getters, state }, json) {
      dispatch("setConversationState", json.conversation);

      commit("set_feedback_api_message", json.response || json.responses);

      if (getters["isConversationExpired"] && !state.expiredMessageAdded) {
        const sessionStorageChatPanelConversation = sessionStorage.getItem('chatPanelConversation');
        let sessionStorageMessages = [];
        if (sessionStorageChatPanelConversation) {
          const parsedSessionStorageConversation = JSON.parse(sessionStorageChatPanelConversation);
          sessionStorageMessages = parsedSessionStorageConversation.response ? parsedSessionStorageConversation.response : parsedSessionStorageConversation.responses
        }
        const expiredMessage = [...sessionStorageMessages, ...[createStatusMessageObject(state.labels["chatExpiredStatusMessage"])]]
        const conversationWithExpiredMessage = {
          conversation: json.conversation,
          ...expiredMessage.length ? { responses: expiredMessage} : {response: expiredMessage}
        }
        dispatch("handleConversationResponse", conversationWithExpiredMessage)
        commit("expired_message_added", true)
        return false
      }
      else {
        dispatch("handleConversationResponse", json);
        sessionStorage.setItem("chatPanelConversation", JSON.stringify({conversation: state.conversation, responses: state.messages, windowOpen: state.chatWindowOpen}));
      }
    },
    handleConversationResponse({ commit, dispatch, state }, json) {
      if (json.response) {
        commit("append_messages", [json.response]);
      } else if (json.responses && json.responses.length) {
        commit("append_messages", json.responses);
      }
      const lastestBotResponse = findLastBotResponseId(state.messages);
      if (lastestBotResponse !== null) {
        commit("set_latest_response_id", lastestBotResponse);
      }
      if (json.conversation.state.poll) {
        dispatch("startPolling");
      } else {
        dispatch("stopPolling");
      }
    },
    async endConversation({ state }) {
      const url = `${process.env.CHATPANEL_END_CONVERSATION}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: process.env.ENVIRONMENT === "PRODUCTION" ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      try {
        const response = await fetch(request);

        if (!response.ok) {
          throw new Error("Failed to end conversation");
        }
        
        sessionStorage.removeItem("chatPanelConversation");
        return true;
      } catch (err) {
        throw new Error("Unable to end conversation.: " + err);
      }
    },
    async sendActionId({ commit, state, dispatch }, { actionId, isFloatingTrigger = false } = {}) {
      const endpoint = isFloatingTrigger ? process.env.CHATPANEL_SEND_FLOATING_TRIGGER_ACTION : process.env.CHATPANEL_SEND_ACTION
      const url = `${endpoint}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: process.env.ENVIRONMENT === "PRODUCTION" ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
        },
        ...process.env.ENVIRONMENT === "PRODUCTION" ? {body: JSON.stringify({ actionId: actionId })} : null,
      });

      try {
        commit("set_is_awaiting_response", true);

        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to send action link");
        }
        const json = await response.json()
        dispatch("handleConversationData", json);
        return true;
      } catch (err) {
        throw new Error("Unable to send action link: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    addClientMessage({commit, state}, payload) {
      const clientMessageId = createClientMessageId(state);
      commit("append_messages", [createMessageObject(payload, clientMessageId)]);
    },
    async sendMessage({ state, commit, dispatch }, payload) {
      const url = `${process.env.CHATPANEL_SEND_MESSAGE}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: process.env.ENVIRONMENT === "PRODUCTION" ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
        },
        ...process.env.ENVIRONMENT === "PRODUCTION" ? {body: JSON.stringify({ text: payload })} : null,
      });
      try {
        dispatch("addClientMessage", payload)
        commit("set_is_awaiting_response", true);
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to send message");
        }
        const json = await response.json();
        dispatch("handleConversationData", json);
        return true;
      } catch (err) {
        throw new Error("Unable to send message: " + err);
      } finally {
        commit("set_is_awaiting_response", false);
      }
    },
    async sendMessageFeedback({ state, commit }, payload) {
      const {feedback, id} = payload;
      const url = `${process.env.CHATPANEL_SEND_MESSAGE_FEEDBACK}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: process.env.ENVIRONMENT === "PRODUCTION" ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
        },
        ...process.env.ENVIRONMENT === "PRODUCTION" ? {body: JSON.stringify({ feedback, id })} : null,
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to send feedback");
        }
        commit("set_message_feedback", {
          id: payload.id,
          value: payload.feedback,
        });
        return true;
      } catch (err) {
        throw new Error("Unable to send feedback: " + err);
      }
    },
    async sendConversationFeedback({ state, commit }, payload) {
      const url = `${process.env.CHATPANEL_SEND_CONVERSATION_FEEDBACK}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: process.env.ENVIRONMENT === "PRODUCTION" ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
        },
        ...process.env.ENVIRONMENT === "PRODUCTION" ? {body: JSON.stringify({ ...payload.feedback, feedbackActionId: state.feedbackActionId })} : null,
      });

      try {
        const response = await fetch(request);
        const json = await response.json()
        commit("set_feedback_payload", payload.feedback.feedback)
        commit("set_feedback_api_message", json)
        if (!response.ok) {
          throw new Error("Failed to send feedback");
        }
        return true;
      } catch (err) {
        throw new Error("Unable to send feedback: " + err);
      }
    },
    async deleteMessageHistory({ state, dispatch }) {
      const url = `${process.env.CHATPANEL_DELETE_MESSAGE_HISTORY}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: process.env.ENVIRONMENT === "PRODUCTION" ? "POST" : "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to delete message history");
        }
        dispatch("resetChat");
        return true;
      } catch (err) {
        throw new Error("Unable to delete message history: " + err);
      }
    },
    async downloadConversation({ state, dispatch }) {
      const url = `${process.env.CHATPANEL_DOWNLOAD_MESSAGE_HISTORY}?conversation_id=${state.conversation.id}`;
      const request = new Request(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        const response = await fetch(request);
        if (!response.ok) {
          throw new Error("Failed to delete message history");
        }
        const blob = new Blob([await response.blob()], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(blob, "Conversation.txt");
        return true;
      } catch (error) {
        throw new Error("Unable to download message history: " + error);
      }
    },
    async startPolling({ commit, state, dispatch }) {
      if (state.pollingInterval) {
        clearInterval(state.pollingInterval);
      }

      const intervalId = setInterval(async () => {
        try {
          const url = `${process.env.CHATPANEL_STATUS_POLLING_ENDPOINT}?conversation_id=${state.conversation.id}&latest_response_id=${state.latestResponseId}`;
          const request = new Request(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          const response = await fetch(request);
          dispatch("handleConversationData", await response.json());

          if (!response.ok) {
            commit("set_connection_error", true);
          } else {
            commit("set_connection_error", false);
          }
        } catch (error) {
          throw new Error(error);
        }
      }, 3000);
      commit("setPollingInterval", intervalId);
    },
    setConversationState({commit}, payload) {
      commit("set_conversation", payload);
    },
    stopPolling({ commit }) {
      commit("clearPollingInterval");
    },
    minimizeChatWindow({ commit }) {
      commit("set_chat_window_open", false);
    },
    showFeedback({ commit }, payload) {
      commit("set_show_feedback", payload);
    },
    resetChat({ state, commit, dispatch, getters }) {
      dispatch("stopPolling");
      if (getters["isConversationOngoing"]) {
        dispatch("endConversation");
      }
      commit("reset_chat_client_state");
    }
  },
  getters: {
    isConversationActive(state) {
      return state.conversation?.id;
    },
    isConversationOngoing(state) {
      return state.conversation?.id && !state.conversation?.state?.expired;
    },
    isConversationExpired(state) {
      return state.conversation?.state?.expired;
    },
    getFeedbackApiMessage(state) {
      if (
        state.feedbackApiMessage?.elements &&
        state.feedbackApiMessage.elements.length
      ) {
        return state.feedbackApiMessage?.elements[0].payload.html;
      }
      return ""
    },
  }
};
