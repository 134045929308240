import { ref, onMounted, computed, inject } from "vue";
import { useStore } from "vuex";
import Loader from "components/Loader/Loader";
import phoneValidator from "foundation/js/inputValidators/phoneValidator"

export default {
  name: "ChatFeedbackPhoneNumber",
  components: {
    Loader
  },
  props: {
    setCurrentStep: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const inputRef = ref(null)
    const phoneNumber = ref(undefined)
    const showPhoneNumberHelpMessage = ref(false)
    const isLoading = ref(false)
    const isClosing = ref(false)
    const handleKeydown = inject('handleKeydown');
    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label]
      if (!returnLabel) console.error("Label does not exist", label)
      return returnLabel ? returnLabel : `[${label}]`
    }

    const phoneNumberValid = computed(() => {
      return phoneNumber.value && phoneValidator(phoneNumber.value)
    })
    
    const feedbackApiMessage = computed(()  => {
      return store.getters["chatPanel/getFeedbackApiMessage"];
    })

    async function sendConversationFeedback() {
      if (!phoneNumberValid.value) {
        showPhoneNumberHelpMessage.value = true
        return
      }
      try {
        isLoading.value = true
        await store.dispatch("chatPanel/sendMessage", phoneNumber.value);
        props.setCurrentStep('ChatFeedbackReview')
      } catch (error) {
        throw new Error(error);
      } finally {
        isLoading.value = false
      }
    }

    const handleEnterKeydown = (event) => {
      if (event.key === "Enter") {
        sendConversationFeedback();
      }
    }

    return {
      handleEnterKeydown,
      handleKeydown,
      isLoading,
      isClosing,
      inputRef,
      feedbackApiMessage,
      phoneNumberValid,
      showPhoneNumberHelpMessage,
      phoneNumber,
      sendConversationFeedback,
      getLabel,
    };
  },
};
