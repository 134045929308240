<script>
import options from "./InvestmentFundTable.options"
export default options
</script>

<template>
  <article class="nyk-investment-fund-table">
    <SearchInputField
      :initSearchParam="searchInput"
      :onEnterCallback="inputEnter"
      :onChangeCallback="inputChanged"
      :placeholder="model.searchPlaceholderLabel" 
      :ariaLabel="model.searchPlaceholderLabel"
      :searchIconLabel="model?.searchIconLabel"
      :closeIconLabel="model?.closeIconLabel"
      />

    <template v-if="tabs.length > 0">
      <FilterTabs
        :tabs="tabs"
        :initActiveTab="activeFilter"
        :callback="filterChanged" />
    </template>

    <DynamicTable :headers="tableHeaders" :rows="tableRows" />
  </article>
</template>
