<script>
import options from "./DocumentArchive.options"
export default options
</script>

<template>
  <article class="nyk-document-archive">
    <div class="nyk-document-archive__header" v-if="header || description">
      <h2 class="nyk-document-archive__header__title">{{ header }}</h2>
      <p class="nyk-document-archive__header__description">{{ description }}</p>
    </div>

    <div class="nyk-document-archive__filters">
      <template v-for="(filter, index) in filters">
        <MultiSelectDropdown
          :initOptions="filter.options"
          :title="filter.title"
          :placeholderLabel="filter.placeholderLabel"
          :onChangeCallback="filter.callback" />
      </template>

      <div class="nyk-document-archive__filters__search">
        <div class="nyk-document-archive__filters__search__icon">
          <svg :aria-label="searchIconLabel" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
            <path
              fill="#07094A"
              fill-rule="evenodd"
              d="M1.2 7.344a6.142 6.142 0 1 1 10.562 4.264l-.155.156A6.142 6.142 0 0 1 1.2 7.344Zm10.892 5.599a7.342 7.342 0 1 1 .849-.849l4.632 4.632.424.425-.848.848-.424-.424-4.633-4.632Z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <input
          type="text"
          class="nyk-document-archive__filters__search__input"
          v-model="searchInputModel"
          v-on:keyup="inputUpdated"
          :placeholder="searchPlaceholder" />

        <button
          v-on:click="emptyInput"
          class="nyk-document-archive__filters__search__close"
          :class="{
            'nyk-document-archive__filters__search__close--show': inputIsNotEmpty,
          }">
          <span class="nyk-icon nyk-icon--ui-close"></span>
        </button>
      </div>
    </div>

    <div role="status" class="nyk-document-archive__results">{{ resultsLabel }}</div>

    <DynamicDocumentList :documentLists="documentLists" />

    <div class="nyk-button-container">
      <button
        class="nyk-button nyk-button--outline-primary"
        v-on:click="getMoreResults"
        v-if="showMore">
        {{ showMoreText }}
      </button>
    </div>
  </article>
</template>
