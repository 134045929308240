<script>
import options from "./SearchInputField.options"
export default options
</script>

<template>
  <article class="nyk-search-input-field">
    <button class="nyk-search-input-field__icon">
      <svg :aria-label="searchIconLabel" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
        <path
          fill="#07094A"
          fill-rule="evenodd"
          d="M1.2 7.344a6.142 6.142 0 1 1 10.562 4.264l-.155.156A6.142 6.142 0 0 1 1.2 7.344Zm10.892 5.599a7.342 7.342 0 1 1 .849-.849l4.632 4.632.424.425-.848.848-.424-.424-4.633-4.632Z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <input
      ref="searchInput"
      v-model="searchInputModel"
      v-on:keyup.enter="onSearchEnter"
      v-on:keyup="inputUpdated"
      :placeholder="placeholder"
      :aria-label="ariaLabel"
      autocomplete="off"
      class="nyk-search-input-field__input" />

    <button
      :aria-label="ariaLabels?.clearButtonLabel ?? 'Ryd s�gefelt'"
      v-on:click="emptyInput"
      class="nyk-search-input-field__close"
      :class="{
        'nyk-search-input-field__close--show': inputIsNotEmpty,
      }">
      <span :aria-label="closeIconLabel" class="nyk-icon nyk-icon--ui-close"></span>
    </button>
  </article>
</template>
