<script>
import options from "./ChatWindow.options"
export default options
</script>

<template>
    <div class="nyk-chat-window">
        <div class="nyk-chat-window__header">
            <template v-if="showMenuButton">
                <button @click="openMenu" v-on:keydown.tab.shift="handleKeydown" data-order="first" :aria-label="getLabel('TopMenuOpenTitle')" :title="getLabel('TopMenuOpenTitle')"
                    class="nyk-chat-window__header-menu-icon-burger nyk-button nyk-button--link-icon">
                    <svg class="nyk-button__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" role="presentation" aria-hidden="true">
                        <path fill="#07094A" fill-rule="evenodd"
                            d="M0 0h16v1.78H0V0Zm0 7.11h10v1.78H0V7.11Zm16 7.109H0v1.78h16v-1.78Z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </template>
            <template v-else>
                <button v-on:keydown.tab.shift="handleKeydown" data-order="first" @click="navigateBack" v-if="showBackButton"
                    class="nyk-chat-window__header-menu-icon-back  nyk-button nyk-button--link-icon" :aria-label="getLabel('TopMenuNavigationBackTitle')" :title="getLabel('TopMenuNavigationBackTitle')">
                    <svg class="nyk-button__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 11" role="presentation" aria-hidden="true">
                        <path stroke="#212226" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="m5 1.272-4 4 4 4" />
                    </svg>
                </button>
            </template>
            <div class="nyk-chat-window__header-action-buttons">
                <button @click="minimizeChatWindow" v-on:keydown.shift.tab="handleKeydown" :data-order="currentStep === 'ChatFeedbackReview' || 'ChatFeedbackPhoneNumber' ? 'first': null"
                    class="nyk-chat-window__header-action-minimize nyk-button nyk-button--link-icon" :aria-label="getLabel('TopMenuMinimizeTitle')" :title="getLabel('TopMenuMinimizeTitle')">
                    <svg class="nyk-button__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8" role="presentation" aria-hidden="true">
                        <path fill="#07094A" fill-rule="evenodd"
                            d="m1.282 0 5.436 5.437L12.156 0l1.281 1.282L6.718 8 0 1.282 1.282 0Z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
                <button @click="endConversation" v-on:keydown.tab="handleKeydown" :data-order="currentStep === 'ChatFeedbackReview' ? 'last': null"
                    class="nyk-chat-window__header-action-close nyk-button nyk-button--link-icon nyk-button--link-circle" :aria-label="getLabel('TopMenuCloseTitle')" :title="getLabel('TopMenuCloseTitle')">
                    <svg class="nyk-button__icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="presentation" aria-hidden="true">
                        <path fill="#07094A" fill-rule="evenodd"
                            d="M17.563 7.5 7.502 17.56l-1.06-1.06 10.06-10.06 1.06 1.06Z" clip-rule="evenodd" />
                        <path fill="#07094A" fill-rule="evenodd"
                            d="m7.502 6.44 10.06 10.06-1.06 1.06L6.442 7.5l1.06-1.06Z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="nyk-chat-window__content" :class="{ 'nyk-chat-window__content--chat-active': chatIsShown }"
            ref="chatWindowContent">
            <div class="nyk-chat-window__scroll-container">
                <div class="nyk-chat-window__message-container" ref="messageContainer" v-if="chatIsShown">
                    <div v-if="initialMessage" class="nyk-chat-window__initial-message" v-html="initialMessage"></div>
                    <slot name="messages"></slot>
                    <slot name="loading-messages"></slot>
                </div>
                <ChatFeedback v-if="showFeedback"></ChatFeedback>
                <ChatConnectionError v-if="showConnectionError"></ChatConnectionError>
                <Transition name="menuAppear" appear>
                    <ChatMenu v-if="menuIsOpen"></ChatMenu>
                </Transition>
            </div>
        </div>
        <slot name="input-message" v-if="chatIsShown"></slot>
    </div>
</template>