<script>
    import options from "./ArticlesInsight.options"
    export default options
</script>

<template>
    <article class="nyk-articles-insight">
        <div class="nyk-articles-insight__header">
            <h2 class="nyk-articles-insight__header__title">{{ title }}</h2>
            <MultiSelectDropdown title="Emner"
                                 placeholderLabel="Filtrer på emne"
                                 :initOptions="subjectOptions"
                                 :onChangeCallback="handleSubjectsCallback"
                                 :showArrow="true" />
            <MultiSelectDropdown title="Typer"
                                 placeholderLabel="Filtrer på type"
                                 :initOptions="typeOptions"
                                 :onChangeCallback="handleTypesCallback"
                                 :showArrow="true" />
        </div>

        <div class="nyk-articles-insight__content">
            <section class="nyk-standard-card-layout">
                <div class="nyk-standard-card-layout__content">
                    <template v-for="(result, index) in results" :key="index">
                        <a :href="result.url" class="nyk-standard-card">
                            <article class="nyk-standard-card__content">
                                <div class="nyk-standard-card__content__image">
                                    <picture>
                                        <source :srcset="result.thumbnailUrlMobile" media="(max-width: 600px)" />
                                        <source :srcset="result.thumbnailUrlTablet" media="(max-width: 905px)" />
                                        <img class="nyk-standard-card__content__image__img" :src="result.thumbnailUrl" alt="" role="presentation" />
                                    </picture>
                                </div>

                                <div class="nyk-standard-card__content__body">
                                    <div class="nyk-standard-card__content__body__meta">
                                        <div class="nyk-standard-card__content__body__meta__info">
                                            <span class="nyk-standard-card__content__body__meta__info__tag">{{ result.tag }}</span>
                                            <span class="nyk-standard-card__content__body__meta__info__name">{{ result.category }}</span>
                                        </div>
                                        <span class="nyk-standard-card__content__body__meta__read-time">{{ result.datePublished }}</span>
                                    </div>

                                    <div class="nyk-standard-card__content__body__content">
                                        <h3 class="nyk-standard-card__content__body__content__title">
                                            {{ result.title }}
                                        </h3>
                                    </div>
                                </div>
                            </article>
                        </a>
                    </template>
                </div>
                <template v-if="loadMore">
                    <div class="nyk-standard-card-layout__actions">
                        <button :disabled="fetching" v-on:click="getMoreResults" class="nyk-button nyk-button--outline-primary">
                            {{ getMoreButtonText }}
                        </button>
                    </div>
                </template>
            </section>
        </div>
    </article>
</template>