<script>
import options from './NewLoanCalculatorResultDesktop.options'
export default options
</script>

<template>
    <article class="nyk-new-loan-calculator-result-desktop">
        <button v-if="summaries.length > 1" v-on:click="quitCompareMode" class="nyk-new-loan-calculator-result__back-button">
            <div class="nyk-new-loan-calculator-result__back-button-icon">
                <div class="nyk-button__icon">
                    <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13">
                        <path fill="#07094A" d="M16 7.218V5.762H3.005L7.461.748 6.694 0 0 6.51 6.694 13l.767-.748-4.456-5.034H16Z" />
                    </svg>
                </div>
            </div>
            <span class="nyk-new-loan-calculator-result__back-button-label">{{ labels.backButtonLabel }}</span>
        </button>

        <h1 class="nyk-new-loan-calculator-result__title" aria-live="polite" v-if="labels.resultTitle && labels.compareTitle">
            {{ summaries.length > 1 ? labels.compareTitle : labels.resultTitle }}
        </h1>

        <div
            class="nyk-new-loan-calculator-result__info-container"
            :class="{ 'nyk-new-loan-calculator-result__info-container--multiple': summaries.length > 1 }">
            <template v-if="summaries.length === 1" v-for="(summary, singleSummaryIndex) in summaries" :key="singleSummaryIndex">
                <CalculatorSummary
                    v-if="summary"
                    :data="summary"
                    :labels="labels"
                    :hasHousingTypes="getLoanTypesForSummary(singleSummaryIndex).length > 1"
                    :onCallback="() => switchLoanTypeWithRedirect(singleSummaryIndex)" />

                <CalculatorInfoBox v-if="infoBox[singleSummaryIndex]" :data="infoBox[singleSummaryIndex]" />

                <div class="nyk-new-loan-calculator-result__info-container-actions">
                    <button
                        :class="`nyk-button ${
                            showSendCalculation ? 'nyk-button--filled-primary-dark' : 'nyk-button--outline-primary'
                        } nyk-button--send-calc nyk-js--send-calc`"
                        :aria-expanded="showSendCalculation"
                        v-on:click="openSendCalculationsModal">
                        {{ labels.sendCalculationButtonLabel }}
                    </button>
                    <button class="nyk-button nyk-button--outline-primary" v-on:click="openCompareLoanModal">
                        {{ labels.compareLoanButtonLabel }}
                    </button>
                    <button
                        class="nyk-button nyk-button--outline-primary"
                        aria-haspopup="true"
                        v-on:click="() => adjustLoanWithRedirect(singleSummaryIndex)">
                        {{ labels.adjustLoanButtonLabel }}
                    </button>
                </div>
            </template>

            <template v-else v-for="(summary, multipleSummariesIndex) in summaries" :key="multipleSummariesIndex">
                <div class="nyk-new-loan-calculator-result__info-container-wrapper">
                    <CalculatorSummary
                        v-if="summary"
                        :data="summary"
                        :labels="labels"
                        :onCallback="() => switchLoanType(multipleSummariesIndex)"
                        :hasHousingTypes="getLoanTypesForSummary(multipleSummariesIndex).length > 1"
                        :hasGraph="false" />

                    <button
                        class="nyk-button nyk-button--outline-primary"
                        aria-haspopup="true"
                        v-on:click="() => adjustLoan(multipleSummariesIndex)">
                        {{ labels.adjustLoanButtonLabel }}
                    </button>
                </div>
            </template>
        </div>

        <div class="nyk-new-loan-calculator-result__send-calc" v-if="sendCalculation && showSendCalculation">
            <SendCalculation
                :labels="sendCalculation.labels"
                :salesforceidApiUrl="sendCalculation.salesforceidApiUrl"
                :sendCalculationApiUrl="sendCalculation.sendCalculationApiUrl"
                :userInformationApiUrl="sendCalculation.userInformationApiUrl"
                :calculatorType="sendCalculation.calculatorType"
                :backgroundColor="sendCalculation.backgroundColor"
                :initStartFlow="true"
                :onCloseCallback="closeSendCalculationModal" />
        </div>

        <div class="nyk-new-loan-calculator-result__table-sections">
            <template v-if="tables.length === 1" v-for="(table, tableIndex) in tables" :key="tableIndex">
                <section class="nyk-new-loan-calculator-result__table-sections__content" :id="`table_${tableIndex}`">
                    <template v-for="(group, groupIndex) in table" :key="groupIndex">
                        <h2 class="nyk-new-loan-calculator-result__table-sections__content-title">{{ group.title }}</h2>

                        <template v-for="(table, tableIndex) in group.tables" :key="tableIndex">
                            <CalculatorTable
                                :headers="getTableHeaders(table)"
                                :tooltip="table.tooltip"
                                :collapse="table.value ? true : false"
                                :rows="getTableRows(table)"
                                :labels="labels" />
                        </template>
                    </template>
                </section>
            </template>

            <CalculatorCompareTable 
                v-if="tables.length > 1" 
                :tables="tables" 
                :labels="labels" 
            />
        </div>

        <SendCalculation
            v-if="sendCalculation && summaries.length === 1"
            :labels="sendCalculation.labels"
            :salesforceidApiUrl="sendCalculation.salesforceidApiUrl"
            :sendCalculationApiUrl="sendCalculation.sendCalculationApiUrl"
            :userInformationApiUrl="sendCalculation.userInformationApiUrl"
            :calculatorType="sendCalculation.calculatorType"
            :backgroundColor="sendCalculation.backgroundColor"
            :redirectLink="sendCalculation.redirectLink"
            :onResetCallback="() => {}"/>
    </article>
</template>
