import { onMounted, ref } from "vue"

export default {
    name: "FilterTabs",
    props: {
        initActiveTab: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
        callback: {
            type: Function,
            required: false,
        },
        canShowDropdown: {
            type: Boolean,
            required: false,
            default: true,
        },
        dropdownLabel: {
            type: String,
            required: false,
        }
        
    },
    setup(props) {
        const tabContainer = ref(null)
        const lineElement = ref(null)
        const activeTab = ref(props.initActiveTab)

        onMounted(() => {
            startTabMutationObserver()
            const indexOfActiveTab = props.tabs.findIndex((tab) => tab.id === props.initActiveTab)
            tabContainer.value.querySelectorAll("button")[indexOfActiveTab].setAttribute("aria-selected", true)
        })

        const drawTabActiveBorder = (activeElm) => {
            const tabContainerLeft = tabContainer.value.getBoundingClientRect().left

            const activeElmLeft = activeElm.getBoundingClientRect().left
            const lineLeftPos = activeElmLeft - tabContainerLeft + tabContainer.value.scrollLeft

            lineElement.value.style.left = `${lineLeftPos}px`
            lineElement.value.style.width = `${activeElm.clientWidth}px`
        }

        const handleTabChanges = function (mutationsList) {
            mutationsList.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.target.getAttribute("aria-selected") === "true") {
                    drawTabActiveBorder(mutation.target)
                }
            })
        }

        const startTabMutationObserver = () => {
            const config = { attributes: true, childList: true, subtree: true }

            const observer = new MutationObserver(handleTabChanges)
            observer.observe(tabContainer.value, config)
        }

        const changeTab = (tabId) => {
            activeTab.value = tabId
            if (props.callback) {
                props.callback(tabId)
            }
        }

        return {
            changeTab,
            tabContainer,
            lineElement,
            activeTab,
        }
    },
}
