<script>
    import options from './SwitchLoanTypeContent.options'
    export default options
</script>

<template>
    <article class="nyk-switch-loan-type-content">
        <ul class="nyk-switch-loan-type-content__list">
            <li class="nyk-switch-loan-type-content__list-item" v-for="(loan, index) in currentHousingType" :key="index">
                <h3 class="nyk-switch-loan-type-content__list-item-title">
                    {{ loan.title }}
                    <span v-if="loan.type === selectedLoanType" class="nyk-switch-loan-type-content__list-item-pill">
                        {{
                        labels.selectedLoanTypeLabel
                        }}
                    </span>
                </h3>
                <p class="nyk-switch-loan-type-content__list-item-description">{{ loan.description }}</p>
                <ul class="nyk-switch-loan-type-content__list-item-bullets">
                    <li class="nyk-switch-loan-type-content__list-item-bullets-item"
                        v-for="(bullet, bulletsIndex) in loan.bullets"
                        :key="bulletsIndex">
                        <span>{{ bullet.key }}</span>
                        <span>{{ bullet.value }}</span>
                    </li>
                </ul>

                <button v-if="loan.type !== selectedLoanType"
                        v-on:click="changeLoanType(loan.type)"
                        class="nyk-switch-loan-type-content__list-item-action"
                        :aria-label="`${labels.loanTypeButtonLabel}: ${loan.title}`">
                    {{ labels.loanTypeButtonLabel }}
                    <div class="nyk-switch-loan-type-content__list-item-action-button nyk-button nyk-button--link-circle-arrow right">
                        <div class="nyk-button__icon">
                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                <path fill="#07094A"
                                      d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                            </svg>
                        </div>
                    </div>
                </button>
            </li>
        </ul>
    </article>
</template>