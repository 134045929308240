import { computed, onMounted, onUpdated, ref } from 'vue'

import mapThemes from './methods/mapThemes'
import updatePins from './methods/updatePins'
import updateBounds from './methods/updateBounds'
import initMap from './methods/initMap'

export default {
    name: 'GoogleMapsWithMarkers',
    props: {
        // optional clickhandler when user clicks a marker
        clickLocation: {
            type: Function,
        },
        // optionnal focus points used to direct the map rendering
        focusPoints: {
            type: Array,
            default: [],
        },
        // optional data providing info on the currently highlighted or "hovered" marker
        highlightLocation: {
            type: Object,
            default: null,
        },
        // optional mouseover handler for markers
        hoverEnterLocation: {
            type: Function,
            default: null,
        },
        // optional mouseout handler for markers
        hoverLeaveLocation: {
            type: Function,
        },
        // array with lat/lng and info for markers to be rendered
        locations: {
            type: Array,
            default: [],
        },
        // apikey for google maps
        mapkey: {
            type: String,
            required: true,
        },
        // optional styles as string
        style: {
            type: String,
        },
        // theme name. Name/theme must be defined in mapThemes.js
        theme: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const mapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${props.mapkey}&language=da-DK&region=dk&libraries=places`
        const map = ref(null)
        const pins = ref([])
        const mapContainer = computed(() => document.querySelector('#mapcontainer'))
        const defaultZoom = 7
        const defaultCenter = computed(() => (window.google ? new window.google.maps.LatLng(56.27206, 10.3) : undefined))
        const currentMapTheme = ref({})
        const mapLocations = computed(() => props.locations ?? [])
        const localHighlightLocation = ref(null)
        const highlightLocation = computed({
            get() {
                return props.highlightLocation ?? localHighlightLocation.value
            },
            set(location) {
                localHighlightLocation.value = location
            },
        })

        const setAltTextForImg = () => {
            const allImgPins = mapContainer.value.querySelectorAll('img[draggable="false"]')
            if (!allImgPins.length) {
                setTimeout(setAltTextForImg, 50)
                return
            }
            allImgPins.forEach((img) => {
                const parent = img.parentNode
                const altText = parent.getAttribute('title')
                img.setAttribute('alt', altText)
            })
        }

        onMounted(() => {
            emit('current-zoom', defaultZoom)
            const loadMapScript = document.createElement('script')
            loadMapScript.type = 'text/javascript'
            loadMapScript.src = mapsScriptUrl
            loadMapScript.onload = () => {
                if (mapContainer.value && window.google) {
                    currentMapTheme.value = mapThemes[props.theme]()
                    map.value = initMap(mapContainer, initialMapOptions)
                    pins.value = updatePins(
                        props,
                        mapLocations,
                        pins,
                        markerThemeExtractor,
                        currentMapTheme,
                        highlightLocation,
                        map,
                        updateMarkerHighlight
                    )

                    setTimeout(setAltTextForImg, 50)
                    map.value.addListener('zoom_changed', () => {
                        emit('current-zoom', map.value.getZoom())
                    })
                }
            }

            document.body.appendChild(loadMapScript)
        })

        onUpdated(() => {
            if (map.value) {
                map.value = initMap(mapContainer, initialMapOptions)
            }
            updateLocalState(props, pins)
        })

        const initialMapOptions = computed(() => {
            return {
                zoom: defaultZoom,
                center: {
                    lat: defaultCenter.value.lat(),
                    lng: defaultCenter.value.lng(),
                },
                scrollwheel: false,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeId: 'roadmap',
                mapTypeControlOptions: {
                    mapTypeIds: ['roadmap', 'satellite'],
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
                clickableIcons: false,
                gestureHandling: 'cooperative',
                ...currentMapTheme.value.mapOptions,
            }
        })

        const updateMarkerHighlight = function () {
            pins.value.forEach((pin) => {
                if (pin.marker && pin.location) {
                    const highlighted = highlightLocation.value == pin.location
                    const useMediumIcon = pin.location.UseMediumIcon
                    const useBigIcon = pin.location.UseBigIcon
                    //pin.marker.setIcon(markerThemeExtractor(highlighted, useMediumIcon, useBigIcon))
                }
            })
        }

        const markerThemeExtractor = function (highlighted, useMediumIcon, useBigIcon) {
            if (useBigIcon) {
                return highlighted ? currentMapTheme.value.markerBigHighlight : currentMapTheme.value.markerBig
            } else if (useMediumIcon) {
                return highlighted ? currentMapTheme.value.markerMediumHighlight : currentMapTheme.value.markerMedium
            } else {
                return highlighted ? currentMapTheme.value.markerHighlight : currentMapTheme.value.markerDefault
            }
        }

        const updateLocalState = function (props, pins) {
            if (map.value !== null) {
                pins.value = updatePins(
                    props,
                    mapLocations,
                    pins,
                    markerThemeExtractor,
                    currentMapTheme,
                    highlightLocation,
                    map,
                    updateMarkerHighlight
                )
            }

            if (pins.value !== null && map.value !== null) {
                updateBounds(props, map, mapLocations, defaultZoom)
                updateMarkerHighlight()
            }
        }
    },
}
