<script>
    import options from './SendCalculationStepInfo.options'
    export default options
</script>

<template>
    <article class="nyk-send-calculation" :class="{ 'nyk-send-calculation--multi-buttons': redirectLink || labels?.anchorId || labels?.anchorTitle }">
        <div class="nyk-send-calculation__content">
            <div class="nyk-send-calculation__content__info">
                <p class="nyk-send-calculation__content__info__description">
                    <strong class="nyk-send-calculation__content__info__description__label">
                        {{ currentStepTexts.boldPrefixText }}
                    </strong>
                </p>
                <p v-if="currentStepTexts.mainText" class="nyk-send-calculation__content__info__description__text">
                    {{ currentStepTexts.mainText }}
                </p>
            </div>
            <template v-if="labels?.anchorId && labels?.anchorTitle">
                <div class="nyk-send-calculation__content__actions">
                    <button v-on:click="nextStepCallback([])" class="nyk-button nyk-button--outline-primary">
                        {{ currentStepTexts.buttonText }}
                    </button>
                    <a :href="`#${labels?.anchorId}`" class="nyk-button nyk-button--filled-primary" @click="navigateToAnchor">{{ labels?.anchorTitle }}</a>
                </div>
            </template>

            <template v-else-if="redirectLink && redirectLink.url && redirectLink.text">
                <div class="nyk-send-calculation__content__actions">
                    <button v-on:click="nextStepCallback([])" class="nyk-button nyk-button--outline-primary">
                        {{ currentStepTexts.buttonText }}
                    </button>
                    <a :href="redirectLink.url" class="nyk-button nyk-button--filled-primary" :target="redirectLink.target">{{ redirectLink.text }}</a>
                </div>
            </template>

            <template v-else>
                <button v-on:click="nextStepCallback([])"
                        class="nyk-send-calculation__content__link nyk-button nyk-button--filled-primary-dark">
                    {{ currentStepTexts.buttonText }}
                </button>

                <button
                    :aria-label="labels.ariaCloseButtonLabel"
                    v-on:click="onCloseCallback"
                    class="nyk-signup-step-receipt__close nyk-js--send-calc-close-button">
                    <span class="nyk-icon nyk-icon--ui-close"></span>
                </button>
            </template>
        </div>
    </article>
</template>