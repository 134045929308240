<script>
import options from './CenterMap.options'
export default options
</script>

<template>
  <article class="nyk-center-map" :class="{ 'nyk-center-map--hidden-mobile': !showMapsMobile }">
    <div class="nyk-center-map__map-search-section">
      <div class="nyk-center-map__controls">
        <div class="nyk-center-map__controls__search">
          <div class="nyk-form nyk-js--form">
            <div class="nyk-form__content">
              <input
                ref="googlePlacesSearchField"
                class="nyk-form__content__input"
                type="text"
                maxlength="10000"
                autocomplete="off"
                id="googlePlacesSearch"
                name="googlePlacesSearch"
                aria-required="true" />

                <label for="googlePlacesSearch" class="nyk-form__content__label">{{labels.searchPlaceholderLabel}}</label>

                            <button
                                @click="resetCenterSearch"
                                class="nyk-form__content__reset-icon nyk-form-js--reset"
                                aria-label="Slet indtastning">
                                <span class="nyk-icon nyk-icon--ui-close"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="nyk-center-map__controls__dropdown Form__Element">
                    <MultiSelectDropdown
                        :initOptions="multiSelectCategoriesOptions"
                        :onChangeCallback="filterCategories"
                        :placeholderLabel="labels.categorySelectPlaceholder"
                        :title="labels.titleLabel" />
                </div>
            </div>

            <div class="nyk-center-map__switch-view-mobile">
                <div class="nyk-center-map__switch-view-mobile__body">
                    <button
                        v-on:click="handleShowMapMobile"
                        class="nyk-center-map__switch-view-mobile__body__item"
                        :class="{ 'nyk-center-map__switch-view-mobile__body__item--active': showMapsMobile }">
                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18">
                            <path fill="#fff" d="M7.25 12.127 4.123 9l-1.065 1.057L7.25 14.25l9-9-1.057-1.058-7.943 7.935Z" />
                        </svg>
                        Kort
                    </button>
                    <button
                        v-on:click="handleShowListResultsMobile"
                        class="nyk-center-map__switch-view-mobile__body__item"
                        :class="{ 'nyk-center-map__switch-view-mobile__body__item--active': !showMapsMobile }">
                        Liste
                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18">
                            <path fill="#fff" d="M7.25 12.127 4.123 9l-1.065 1.057L7.25 14.25l9-9-1.057-1.058-7.943 7.935Z" />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="nyk-center-map__zoom-level" aria-live="polite">Zoom level: {{ currentZoom }}</div>

            <div class="nyk-center-map__map-section">
                <div
                    class="nyk-center-map__map-section__suggestions"
                    :class="{ 'nyk-center-map__map-section__suggestions--shown': showFilteredResults }">
                    <ul class="nyk-center-map__map-section__suggestions__list">
                        <template v-for="(info, index) in centerInformationFiltered" :key="index">
                            <template v-if="index < showListMobileCount">
                                <li class="nyk-center-map__map-section__suggestions__list__item">
                                    <a class="nyk-center-map__map-section__suggestions__list__item__link" :href="info.centerPageUrl">
                                        <div class="nyk-center-map__map-section__suggestions__list__item__link__card">
                                            <div class="nyk-center-map__map-section__suggestions__list__item__link__card__header">
                                                <h3 class="nyk-center-map__map-section__suggestions__list__item__link__card__header__title">
                                                    {{ info.name }}
                                                </h3>
                                                <p
                                                    class="nyk-center-map__map-section__suggestions__list__item__link__card__header__address">
                                                    {{ info.address }}
                                                </p>
                                                <p
                                                    class="nyk-center-map__map-section__suggestions__list__item__link__card__header__postal-code">
                                                    {{ info.postal }}
                                                </p>
                                            </div>
                                            <!-- <div
                                              class="nyk-center-map__map-section__suggestions__list__item__link__card__distance">
                                              {{ getDistance(info) }}
                                            </div> -->
                                        </div>

                                        <div class="nyk-center-map__map-section__suggestions__list__item__arrow">
                                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                                                <path
                                                    fill="#07094A"
                                                    d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </template>
                        </template>
                    </ul>

                    <div
                        v-if="centerInformationFiltered && centerInformationFiltered.length > showListMobileCount"
                        class="nyk-button-container">
                        <button v-on:click="handleShowAllListMobile" class="nyk-button nyk-button--outline-primary">Vis flere</button>
                    </div>
                </div>

                <GoggleMapsWithMarkers
                    :focusPoints="focusPoints"
                    :locations="centerInformationFiltered"
                    @current-zoom="updateCurrentZoom"
                    theme="findNykreditTheme"
                    :mapkey="mapkey" />
            </div>
        </div>
    </article>
</template>
