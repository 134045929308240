<script>
import options from "./ContactStepIntro.options"
export default options
</script>

<template>
  <article class="nyk-signup-step nyk-contact-step-intro">
    <div class="nyk-contact-step-intro__content">
      <div class="nyk-contact-step-intro__content__image">
        <picture>
          <!-- add sources if we have different size and versions for different widths -->
          <source :srcset="imageSource" media="(max-width: 600px)" />
          <source :srcset="imageSource" media="(max-width: 905px)" />
          <img
            class="nyk-contact-step-intro__content__image__img"
            :src="imageSource"
            :alt="imageAltText ?? 'Nykredit billede'" />
        </picture>
      </div>
      <div class="nyk-contact-step-intro__content__info">
        <div class="nyk-contact-step-intro__content__info__header">
          <h2 class="nyk-contact-step-intro__content__info__header__title">
            {{ currentStepTexts.title }}
          </h2>
        </div>

        <div class="nyk-contact-step-intro__content__info__content">
          <p class="nyk-contact-step-intro__content__info__content__description" 
            v-html="currentStepTexts.summary">
          </p>
        </div>

        <div class="nyk-contact-step-intro__content__info__action">
          <div class="nyk-contact-step-intro__content__info__action__link">
            <span class="nyk-contact-step-intro__content__info__action__link__name">{{
              currentStepTexts.next
            }}</span>
            <button
              v-on:click="nextStepCallback([])"
              class="nyk-contact-step-intro__content__info__action__link__button nyk-button nyk-button--link-circle-arrow right">
              <div class="nyk-button__icon" :aria-label="currentStepTexts.next">
                <svg role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12"><path fill="#07094A" d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path></svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
