import {ref, onMounted, watch, onUnmounted} from "vue";
import { useStore } from "vuex";
import startTypingAnimation from "foundation/js/typingAnimation";

export default {
    name: 'ChatPanelFloatingTrigger',
    props: {
        chatPanelActionId: {
            type: String,
            required: true
        },
        floatingTriggerText: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const buttonTextRef0 = ref(undefined);
        const buttonTextRef1 = ref(undefined);
        const triggerElementRef = ref(null)
        const animationFinished = ref(false)
        function initActionId() {
            store.dispatch("chatPanel/openChatAndHandleActionId", props.chatPanelActionId)
        }

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting && !animationFinished.value) {
                animationFinished.value = true;
                startAnimation()
              }
            });
          };

        onMounted(() => {
            const observer = new IntersectionObserver(handleIntersection, {
              threshold: 0.5
            });
      
            if (triggerElementRef.value) {
              observer.observe(triggerElementRef.value);
            }
      
        });
        
        onUnmounted(() => {
          if (triggerElementRef.value) {
            observer.unobserve(triggerElementRef.value);
          }
        });

        function startAnimation() {
            const chatButtonText1 = props.floatingTriggerText.text1;
            const chatButtonText2 = props.floatingTriggerText.text2;
            watch(
            buttonTextRef0,
            (newVal) => {
                if (newVal) {
                startTypingAnimation(
                    [buttonTextRef0, buttonTextRef1],
                    [chatButtonText1, chatButtonText2]
                );
                }
            },
            { immediate: true }
            );
        }

        return {
            triggerElementRef,
            buttonTextRef0,
            buttonTextRef1,
            initActionId
        }
    }
}