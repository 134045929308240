<script>
import options from './FilterTabs.options'
export default options
</script>

<template>
    <div class="nyk-filter-wrapper">

        <div class="nyk-filter-tabs" :class="{ 'nyk-filter-tabs--mobile-hidden' : canShowDropdown }" role="tablist" ref="tabContainer">
            <span class="nyk-filter-tabs__line" ref="lineElement"></span>
            <template v-for="(tab, index) in tabs" :key="index">
                <button
                    :aria-selected="tab.id === activeTab"
                    role="tab"
                    :id="tab.id"
                    v-on:click="changeTab(tab.id)"
                    class="nyk-filter-tabs__tab">
                    <div class="nyk-filter-tabs__tab__button">
                        <span class="nyk-filter-tabs__tab__button__text">
                            {{ tab.title }}
                        </span>
                    </div>
                </button>
            </template>
        </div>

        <div v-if="canShowDropdown" class="nyk-filter-dropdown">
            <div class="nyk-form nyk-js--form nyk-form--select">
                <div class="nyk-form--select__content">
                    <select :aria-label="dropdownLabel" class="nyk-form--select__content__select" v-model="activeTab" @change="changeTab($event.target.value)">
                        <option v-for="(tab, index) in tabs" :key="index" :value="tab.id">{{ tab.title }}</option> 
                    </select>
                </div>  
            </div>
        </div>
    </div>
</template>
