import { createApp } from "vue"
import ArticlesInsight from "./ArticlesInsight"

const el = ".nyk-component--articles-insight"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((ArticlesInsightDOM) => {
      const props = {
        apiUrl: ArticlesInsightDOM.dataset.apiUrl,
        title: ArticlesInsightDOM.dataset.title,
        getMoreButtonText: ArticlesInsightDOM.dataset.getMoreButtonText,
        takeCount: ArticlesInsightDOM.dataset.takeCount ? parseInt(ArticlesInsightDOM.dataset.takeCount) : 9,
        subjects: ArticlesInsightDOM.dataset.subjects ? JSON.parse(ArticlesInsightDOM.dataset.subjects) : null,
        types: ArticlesInsightDOM.dataset.types ? JSON.parse(ArticlesInsightDOM.dataset.types) : null,
        excludePages: ArticlesInsightDOM.dataset.excludePages ? JSON.parse(ArticlesInsightDOM.dataset.excludePages) : null,
      }
      const component = createApp(ArticlesInsight, props).use(Store)
      component.mount(ArticlesInsightDOM)
    })
  }
}
