// Getting visiible elements for navigation
export const getNavigationList =  (targetClassName) => {
    const menu = document.querySelector('.nyk-js--menu')
    const items = menu.querySelectorAll(`.${targetClassName}`)
    const filteredItems = Array.from(items).filter((item) => item.checkVisibility())
    return filteredItems
}

// Handle setting the correct item i focus 
const setItemInFocus = (index, list, forward) => {
    if (forward) {
        if (list.length - 1 > index) {
            list[index + 1].focus()
            if (list[index + 1] !== document.activeElement) {
                setItemInFocus(index + 1, list, true)
            }
        } else {
            list[0].focus()
            if (list[0] !== document.activeElement) {
                setItemInFocus(0, list, true)
            }
        }
    } else {
        if (index > 0) {
            list[index - 1].focus()
            if (list[index - 1] !== document.activeElement) {
                setItemInFocus(index - 1, list, false)
            }
        } else {
            setItemInFocus(list.length, list, false)
        }
    }
}

export const setNextItemInFocus = (currentElm, list, callback) => {
    if (list.length === 0) return
    let currentIndex = 0
    list.find((item, index) => {
        if (item === currentElm) currentIndex = index 
    })
    
    if (currentIndex + 1 > list.length - 1) {
        list[0].focus()
        if (list[0] !== document.activeElement) {
            if (callback) {
                callback()
            } else {
                setItemInFocus(0, list, true)
            }
        }
    } else {
        list[currentIndex + 1].focus()
        if (list[currentIndex + 1] !== document.activeElement) {
            setItemInFocus(currentIndex + 1, list, true)
        }
    }
}

export const setPrevItemInFocus = (currentElm, list, callback) => {
    if (list.length === 0) return
    let currentIndex = 0
    list.find((item, index) => {
        if (item === currentElm) currentIndex = index 
    })
    
    if (currentIndex === 0) {
        list[list.length - 1].focus()
        if (list[list.length - 1] !== document.activeElement) {
            setItemInFocus(list.length - 1, list, false)
        }
    } else {
        list[currentIndex - 1].focus()
        if (list[currentIndex - 1] !== document.activeElement) {
            if (callback) {
                callback()
            } else {
                setItemInFocus(currentIndex - 1, list, false)
            }
        }
    }
}

// Check if item is in focus 
const ifItemIsNotInFocus = (list, index, forward) => {
    if (list[index] !== document.activeElement) {
        setItemInFocus(index, list, forward)
    }
}

// Navigation loops
export const handleLoopTrap = (e, forward) => {
    const current = e.target
    const order = current.dataset.order
    const parentLi = current.closest('li')
    const currentList = current.closest('ul')

    if (forward && order === "last") {
        const newCurrent = currentList.querySelector(`.${current.classList[0]}[data-order="first"]`)
        newCurrent?.focus()
    } else if (!forward && order === "first") {
        const newCurrent = currentList.querySelector(`.${current.classList[0]}[data-order="last"]`)
        newCurrent?.focus()
    } else {
        const li = forward ? parentLi?.nextElementSibling : parentLi?.previousElementSibling
        const newCurrentLink = li?.querySelector('a')
        if (newCurrentLink) {
            newCurrentLink?.focus()
        } else {
            const newCurrentButton = li?.querySelector('button')
            newCurrentButton?.focus()
        }
    }
}

export const handleNavigateBackInSubmenu = (e) => {
    const burgerMenuButton = document.querySelector('.nyk-js--burgermenu')
    const subMenuItems = getNavigationList('nyk-js--submenu-item')
    const subNavigationList = [burgerMenuButton, ...subMenuItems]
    const callback = () => {
        let currentIndex = 0
        subNavigationList.find((item, index) => {
            if (item === document.activeElement) {
                currentIndex = index
            }
        })

        subNavigationList[currentIndex - 1].focus()

        if (subNavigationList[currentIndex - 1] !== document.activeElement) {
            setItemInFocus(currentIndex - 1, subNavigationList, false)
        }
    }

    setPrevItemInFocus(e.target, subNavigationList, callback)
}

export const handleNavigateForwardInSubmenu = (e) => {
    const burgerMenuButton = document.querySelector('.nyk-js--burgermenu')
    const subMenuItems = getNavigationList('nyk-js--submenu-item')
    const subNavigationList = [burgerMenuButton, ...subMenuItems]
    const callback = () => {
        subNavigationList[0].focus()
        if (subNavigationList[0] !== document.activeElement) {
            setNextItemInFocus(subNavigationList[0], getNavigationList('nyk-js--submenu-item'))
        }
    }

    setNextItemInFocus(e.target, subNavigationList, callback)
}

export const handleNavigateToPreviousListInSubmenu = (e) => {
    const subNavigationList = getNavigationList('nyk-js--submenu-list')
    const currentList = e.target.closest('.nyk-js--submenu-list')

    if (currentList) {
        subNavigationList.forEach((list, index) => {
            if (list === currentList) {
                if (index === 0) {
                    const subNavigationThree = getNavigationList('nyk-js--submenu-item')
                    subNavigationThree[subNavigationThree.length - 1].focus()
                } else {
                    const items = subNavigationList[index - 1].querySelectorAll('.nyk-js--submenu-item')
                    items[0].focus()
                }
            }
        })
    } else {
        handleNavigateBackInSubmenu(e)
    }
}

export const handleNavigateToNextListInSubmenu = (e) => {
    const subNavigationLists = getNavigationList('nyk-js--submenu-list')
    const currentList = e.target.closest('.nyk-js--submenu-list')

    if (currentList) {
        subNavigationLists.forEach((list, index) => {
            if (list === currentList) {
                if (subNavigationLists.length - 1 >= index + 1) {
                    const items = subNavigationLists[index + 1].querySelectorAll('.nyk-js--submenu-item')
                    items[0].focus()
                } else {
                    const subNavigationThree = getNavigationList('nyk-js--submenu-item')
                    subNavigationThree[subNavigationThree.length - 2].focus()
                }
            }
        })
    } else {
        handleNavigateForwardInSubmenu(e)
    }
}

// Expanding and navigating to the correct menu
export const navigateInMobileSubmenu = (e, forward) => {
    const burgerMenuButton = document.querySelector('.nyk-js--burgermenu')
    const subMenuItems = getNavigationList('nyk-js--mobile-submenu-item')
    const subNavigationMobileList = [burgerMenuButton, ...subMenuItems]
    subNavigationMobileList.forEach((item, index) => {
        if (item === e.target) {

            if (forward && subNavigationMobileList.length - 1 > index) {
                subNavigationMobileList[index + 1].focus()
                ifItemIsNotInFocus(subNavigationMobileList, index + 1, forward)
            } else if (!forward && index > 0) {
                subNavigationMobileList[index - 1].focus()
                ifItemIsNotInFocus(subNavigationMobileList, index - 1, forward)
            } else if (forward) {
                subNavigationMobileList[0].focus()
                ifItemIsNotInFocus(subNavigationMobileList, 0, forward)
            } else {
                subNavigationMobileList[subNavigationMobileList.length - 1].focus()
                ifItemIsNotInFocus(subNavigationMobileList, subNavigationMobileList.length - 1, forward)
            }
        }
    })
}