import { createApp } from "vue"
import ContactCardPermission from "./ContactCardPermission"
import isBoolean from "../../foundation/js/inputValidators/isBoolean"

const el = ".nyk-component--contact-card-permission"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((ContactCardPermissionDOM) => {
      const newsletters = ContactCardPermissionDOM.dataset.newsletters
        ? JSON.parse(ContactCardPermissionDOM.dataset.newsletters)
        : []

      const newslettersUserInputs = newsletters.map((newsletter) => {
        return {
          field: newsletter.name,
          value: false,
          validator: isBoolean,
          isValid: true,
        }
      })

      const props = {
        labels: JSON.parse(ContactCardPermissionDOM.dataset.labels),
        formId: ContactCardPermissionDOM.dataset.formId,
        apiUrl: ContactCardPermissionDOM.dataset.apiUrl,
        newsletterApiUrl: ContactCardPermissionDOM.dataset.newsletterApiUrl,
        newsletters: ContactCardPermissionDOM.dataset.newsletters
          ? JSON.parse(ContactCardPermissionDOM.dataset.newsletters)
          : [],
        newslettersUserInputs: newslettersUserInputs,
        alignIntroImageRight: ContactCardPermissionDOM.dataset.alignIntroImageRight === "true",
        skipFirstStep: ContactCardPermissionDOM.dataset.skipFirstStep === "true",
        imageSource: ContactCardPermissionDOM.dataset.imageSource,
        backgroundColor: ContactCardPermissionDOM.dataset.backgroundColor ?? null,
        assignTo: ContactCardPermissionDOM.dataset.assignTo,
        enableUserTrack: ContactCardPermissionDOM.dataset.enableUserTrack
      }
      const component = createApp(ContactCardPermission, props).use(Store)
      component.mount(ContactCardPermissionDOM)
    })
  }
}
