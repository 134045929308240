import { ref, onMounted } from "vue"
import _sendCalculationStepsCommonProps from "../../foundation/js/configurations/_sendCalculationStepsCommonProps"
import SendCalculationStepActions from "../../../../Features/Blocks/SendCalculation/Actions/SendCalculationStepActions.vue"

export default {
    name: "SendCalculationStepInfo",
    props: _sendCalculationStepsCommonProps,
  components: {
      SendCalculationStepActions,
  },
  setup(props) {
    const contentClassnames = ref("nyk-signup-step__content--animation")

    onMounted(() => {
      setTimeout(() => {
        contentClassnames.value = ""
      }, 1250)
    })


    const navigateToAnchor = ( () => {
      const anchorId = props.labels?.anchorId;

      if (!anchorId) return;

      const targetElement = document.getElementById(anchorId);

      if (targetElement && Array.from(targetElement.classList).some(cls => cls.startsWith("nyk-contact-card"))) {
        document.dispatchEvent(new CustomEvent("triggerAnchorSkip", { bubbles: true }));
      }
    })

    return {
      contentClassnames,
      navigateToAnchor,
    }
  }
}
