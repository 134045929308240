import { onMounted, computed, ref } from "vue"

import debounce from "../../foundation/js/debounce"

import DynamicDocumentList from "../DynamicDocumentList/DynamicDocumentList.vue"
import MultiSelectDropdown from "../MultiSelectDropdown/MultiSelectDropdown.vue"

export default {
  name: "DocumentArchive",
  components: {
    DynamicDocumentList,
    MultiSelectDropdown,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    showMoreText: {
      type: String,
      required: false,
    },
    searchIconLabel: {
      type: String,
      required: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: "Søg på f.eks: Årsregnskab",
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20,
    },
  },
  setup(props) {
    const documentLists = ref([])
    const filters = ref([])
    const activeFilters = ref([])
    const pageSize = ref(props.pageSize)
    const currentPage = ref(1)
    const searchInputModel = ref("")
    const numOfTotalResults = ref(0)
    const numOfShownResults = ref(0)

    onMounted(() => {
      loadFilterFromQueryParams()

      if (props.model) {
        setFilters()
      }

      fetchDocumentList()
    })

    const loadFilterFromQueryParams = () => {
      const params = new URLSearchParams(window.location.search)
      for (const [key, value] of params.entries()) {
        if (key !== 'searchText') {
          activeFilters.value.push({
            type: key,
            params: value.split(','),
          })
        }
      }

      const searchText = params.get('searchText') || ''
      searchInputModel.value = searchText
    }

    const resultsLabel = computed(() => {
      return `Viser ${numOfShownResults.value} af ${numOfTotalResults.value} resultater`
    })

    const showMore = computed(() => {
      return numOfTotalResults.value > numOfShownResults.value
    })

    const inputIsNotEmpty = computed(() => {
      return searchInputModel.value !== ""
    })

    const setFilters = () => {
      props.model.filters.forEach((filter) => {
        const activeFilter = activeFilters.value.find(f => f.type === filter.name)
        filters.value.push({
          title: filter.header,
          placeholderLabel: "Intet valgt",
          options: getOptions(filter.filterOptionItems, activeFilter),
          callback: (res) => handleFilterChanged(filter.name, res),
        })
      })
    }

    const getOptions = (options, activeFilter) => {
      const array = []
      options.forEach((option) => {
        array.push({
          value: option.id,
          name: option.name,
          selected: activeFilter ? activeFilter.params.includes(option.id) : false,
        })
      })
      return array
    }

    const setDocumentLists = (data) => {
      const array = []

      if (currentPage.value === 1) {
        documentLists.value = []
      }

      const isDocumentListEmpty = [...documentLists.value].length === 0

      data.results.forEach((item) => {
        array.push({
          title: item.title,
          date: item.displayDate,
          href: item.url,
          icon: item.icon,
          downloadIconLabel: item.downloadIconLabel,
          fileIconLabel: item.fileIconLabel
        })
      })

      if (isDocumentListEmpty) {
        documentLists.value.push({
          title: props.title,
          items: array,
        })
      } else {
        documentLists.value[0].items.push(...array)
      }

      numOfShownResults.value = documentLists.value[0].items.length
    }

    const updateQueryParams = (key, value) => {
      const params = new URLSearchParams(window.location.search)

      if (value === "") {
        params.delete(key);
      } else {
        params.set(key, value);
      }
      
      const updatedUrl = `${window.location.pathname}?${params.toString()}`
      window.history.replaceState({}, '', updatedUrl)
    }

    const updateFiltersQueryParam = () => {
      activeFilters.value.forEach(filter => {
        const typeFilters = filter.params.join(",")
        updateQueryParams(filter.type, typeFilters)
      })
    }

    const inputUpdated = debounce(() => {
      currentPage.value = 1
      updateQueryParams("searchText", searchInputModel.value)
      fetchDocumentList()
    }, 500)

    const emptyInput = () => {
      searchInputModel.value = ""
      fetchDocumentList()
    }

    const handleFilterChanged = (type, data) => {
      const array = []
      data.forEach((item) => {
        if (item.selected) {
          array.push(item.value)
        }
      })

      const filterExist = activeFilters.value.find((item) => item.type === type)
      if (filterExist) {
        activeFilters.value.find((item) => {
          if (item.type === type) {
            item.params = array
          }
        })
      } else {
        activeFilters.value.push({
          type: type,
          params: array,
        })
      }
      updateFiltersQueryParam()
      currentPage.value = 1
      fetchDocumentList()
    }

    const getMoreResults = () => {
      currentPage.value = currentPage.value + 1
      fetchDocumentList()
    }

    const fetchDocumentList = async () => {
      const url = createApiUrl()
      const request = new Request(url)

      fetch(request)
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error("Didn't work")
          }
        })
        .then((json) => {
          setDocumentLists(json)
          numOfTotalResults.value = json.totalResults
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const createApiUrl = () => {
      let params = ""
      activeFilters.value.forEach((item) => {
        let prefix = params === "" ? "?" : "&"
        params += `${prefix}${item.type}=${item.params.join(",")}`
      })

      let prefix = params === "" ? "?" : "&"
      params += `${prefix}searchText=${searchInputModel.value ?? ""}`
      const take = pageSize.value * currentPage.value
      params += `&skip=${take - pageSize.value}&take=${take}`

      return props.apiUrl + params
    }

    return {
      documentLists,
      filters,
      searchInputModel,
      inputUpdated,
      inputIsNotEmpty,
      emptyInput,
      numOfTotalResults,
      numOfShownResults,
      resultsLabel,
      showMore,
      getMoreResults,
    }
  },
}
