import { computed, ref } from 'vue'

export default {
    name: 'MultiSelectDropdown',
    props: {
        initOptions: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        placeholderLabel: {
            type: String,
            required: false,
        },
        onChangeCallback: {
            type: Function,
            required: false,
        },
        showArrow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const options = ref(props.initOptions)
        const popupOpen = ref(false)
        const labelId = ref(props.title ? props.title.replace(/ /g, '-') : 'multi-select-dropdown-label')
        const dropdownButtonRef = ref(null)
        const dropdownListRef = ref(null)

        const onChangeDropdown = (e) => {
            const elements = e.currentTarget.elements
            options.value = []
            Array.from(elements).forEach((element) => {
                options.value.push({
                    value: element.value,
                    name: element.dataset.name,
                    selected: element.checked,
                })
            })

            if (props.onChangeCallback) {
                props.onChangeCallback(options.value)
            }
        }

        const selectedValues = computed(() => {
            let res = ''
            options.value.forEach((option) => {
                if (option.selected) {
                    if (res === '') {
                        res += option.name
                    } else {
                        res += ', ' + option.name
                    }
                }
            })
            return res
        })

        const optionsIsNotEmpty = computed(() => {
            const numOfSelectedOptions = options.value.filter((option) => {
                return option.selected
            }).length
            return numOfSelectedOptions > 0
        })

        const toggleDropdown = () => {
            popupOpen.value = !popupOpen.value

            if (popupOpen.value) {
                setTimeout(() => {
                    setFirstFocusableElement()
                }, 50)
            }

            document.addEventListener('click', function closeDropdown(e) {
                const insideMuliSelectDropdown = e.target.closest('.nyk-js--multi-select-dropdown')
                if (!insideMuliSelectDropdown) {
                    popupOpen.value = false
                    document.removeEventListener('click', closeDropdown)
                }
            })
        }

        const emptySelectedResults = () => {
            options.value.forEach((option) => {
                option.selected = false
            })
            if (props.onChangeCallback) {
                props.onChangeCallback(options.value)
            }
        }

        const getListOfElements = () => {
            return dropdownListRef.value?.querySelectorAll('input')
        }

        const setFirstFocusableElement = () => {
            const inputs = getListOfElements()

            if (inputs && inputs.length > 0) {
                inputs[0].focus()
            }
        }

        const getActiveInputIndex = (inputs) => {
            let activeInputIndex = 0
            inputs.forEach((input, index) => {
                if (document.activeElement === input) {
                    activeInputIndex = index
                }
            })
            return activeInputIndex
        }

        const navigateBackInList = () => {
            const inputs = getListOfElements()
            if (!inputs) return

            const activeInputIndex = getActiveInputIndex(inputs)

            if (activeInputIndex === 0) {
                inputs[inputs.length - 1].focus()
            } else {
                inputs[activeInputIndex - 1].focus()
            }
        }

        const navigateFrontInList = () => {
            const inputs = getListOfElements()
            if (!inputs) return

            const activeInputIndex = getActiveInputIndex(inputs)

            if (activeInputIndex === inputs.length - 1) {
                inputs[0].focus()
            } else {
                inputs[activeInputIndex + 1].focus()
            }
        }

        const handleKeyTab = (e) => {
            switch (e.key) {
                case 'Tab':
                    if (e.shiftKey) {
                        e.preventDefault()
                        navigateBackInList()
                    } else {
                        e.preventDefault()
                        navigateFrontInList()
                    }
                    break
                case 'ArrowDown':
                case 'ArrowRight':
                    e.preventDefault()
                    navigateFrontInList()
                    break
                case 'ArrowUp':
                case 'ArrowLeft':
                    e.preventDefault()
                    navigateBackInList()
                    break
                case 'Escape':
                    e.preventDefault()
                    popupOpen.value = false
                    dropdownButtonRef.value?.focus()
                    break
                case 'Enter':
                    e.target.click()
                    break
                default:
                    break
            }
        }

        return {
            handleKeyTab,
            dropdownButtonRef,
            dropdownListRef,
            labelId,
            onChangeDropdown,
            toggleDropdown,
            selectedValues,
            options,
            popupOpen,
            emptySelectedResults,
            optionsIsNotEmpty,
        }
    },
}
