<script>
import options from "./FeedbackStepOne.options"
export default options
</script>

<template>
  <fieldset class="nyk-feedback-step-one">
    <div class="nyk-feedback-step-one__content">
      <legend class="nyk-feedback-step-one__title" id="feedback-radio-id">{{ title }}</legend>
      <div class="nyk-feedback-step-one__actions" role="radiogroup" aria-labelledby="feedback-radio-id" v-on:change="inputSelected">
        <template v-for="(option, index) in options" :key="index">
          <div class="nyk-form-choice__content__item nyk-js--choice-wrapper">
            <div class="nyk-form-choice__content__item__choice">
              <input
                class="nyk-form-choice__content__item__choice__input"
                :id="`helpful-${option.value}`"
                type="radio"
                :value="option.value"
                name="helpful" />
              <div class="nyk-form-choice__content__item__choice__circle">
                <div class="nyk-form-choice__content__item__choice__circle__outer"></div>
                <div class="nyk-form-choice__content__item__choice__circle__inner"></div>
              </div>
            </div>
            <label class="nyk-form-choice__content__item__label" :for="`helpful-${option.value}`">{{ option.key }}</label>
          </div>
        </template>
      </div>
    </div>
  </fieldset>
</template>
